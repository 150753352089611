import React, { lazy, Suspense, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr/lib/ReduxToastr';
import { routesByName } from 'constants/routes';
import PrivateRoute from 'components/PrivateRoute';
import UnderConstructionModal from 'components/modals/UnderConstructionModal';
import { toggleUnderConstructionModalAction } from 'modules/layout/store/actions';
import { ReactComponent as Preloader } from 'assets/icons/preloader.svg';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import { initAppAction } from 'modules/app/store/actions';
import UncompletedUserRedirect from 'modules/app/UncompletedUserRedirect';
import UnavailableToolMobilePage from 'components/UnavailableToolMobilePage/UnavailableToolMobilePage';
import HowItWorksModal from 'components/modals/HowItWorksModal/HowItWorksModal';
import AmbassadorRoute from 'components/AmbassadorRoute';
import TradeOpportunitiesContainer from 'modules/ambassadors/TradeOpportunities/TradeOpportunitiesContainer';
import VerificationRemindModal from 'modules/auth/emailVerification/VerificationRemindModal';
import { useStylePreferencesEffect } from './hooks/useStylePreferencesEffect';
import ClaimImageModal from './modules/layout/ClaimImageModal/ClaimImageModal';
import TermsModal from './components/modals/TermsModal/TermsModal';
import PreferencesRemindModal from './modules/getTheLook/preferencesRemindModal';
import ConversationController from './modules/app/ConversationController';
import Footer from './modules/layout/Footer/Footer';
import Header from './modules/layout/Header/Header';
import Terms from './modules/pages/Terms';
import NewsLetterModal from './components/modals/NewsLetterModal/NewsLetterModal';

const Page404 = lazy(() => import('modules/pages/Page404'));
const AmbassadorFaqs = lazy(() =>
  import('./modules/dashboard/ambassadorManagement/AmbassadorFaqs/AmbassadorFaqs'),
);
const UnderConstruction = lazy(() => import('modules/pages/UnderConstruction'));
const PinMediaArea = lazy(() => import('modules/pinMedia/PinMediaContainer'));
const GetThePin = lazy(() => import('./modules/getThePin/GetThePin'));
const EmailVerificationContainer = lazy(() =>
  import('modules/auth/emailVerification/EmailVerificationContainer'),
);
const AccountContainer = lazy(() => import('modules/account/AccountContainer'));
const DashboardContainer = lazy(() => import('modules/dashboard/DashboardContainer'));
const CurateTheLookContainer = lazy(() => import('modules/curateTheLook/CurateTheLookContainer'));
const HomeContainer = lazy(() => import('modules/home/HomeContainer'));
const AmbassadorsContainer = lazy(() => import('modules/ambassadors/AmbassadorsContainer'));
const ContactContainer = lazy(() => import('modules/contact/ContactContainer'));
const RequestTheLookContainer = lazy(() =>
  import('modules/requestTheLook/RequestTheLookContainer'),
);
const NominateAmbassadorContainer = lazy(() =>
  import('modules/ambassadors/nominateAmbassador/NominateAmbassadorContainer'),
);
const GetTheLookContainer = lazy(() => import('modules/getTheLook/GetTheLookContainer'));
const LookBoardDetails = lazy(() => import('modules/lookBoard/LookBoardDetails'));
const ProductDetails = lazy(() => import('modules/product/ProductDetails'));
const ShowcaseDetails = lazy(() => import('modules/showcase/ShowcaseDetails'));
const ReportContainer = lazy(() => import('modules/report/ReportContainer'));
const AboutContainer = lazy(() => import('modules/about/AboutContainer'));
const ShowcasePreview = lazy(() => import('modules/showcase/ShowcasePreview'));
const Privacy = lazy(() => import('modules/pages/privacy/Privacy'));
const Community = lazy(() => import('modules/pages/community/Community'));
const StyleQuizPage = lazy(() => import('./modules/styleQuiz/StyleQuizPage'));
const InspirationImageDetails = lazy(() =>
  import('./modules/inspirationImage/InspirationImageDetails'),
);

const App = () => {
  const dispatch = useDispatch();

  const inited = useSelector((state) => state.app.inited);
  const underConstructionModalOpen = useSelector(
    (state) => state.layout.underConstructionModalOpen,
  );

  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  const user = useSelector((state) => state.auth.user);
  const { pathname } = useLocation();

  useEffect(() => {
    (async () => {
      await dispatch(initAppAction());
    })();
    // eslint-disable-next-line
  }, []);

  useStylePreferencesEffect();

  useEffect(() => {
    const { userAgent } = navigator;
    if (
      userAgent.includes('Prerender (+https://github.com/prerender/prerender)') ||
      userAgent.includes('bot') ||
      userAgent.includes('crawl') ||
      userAgent.includes('slurp')
    ) {
      localStorage.setItem('isRedirect', 'redirected');
      return;
    }

    if (user) {
      if (pathname !== routesByName.home) {
        localStorage.setItem('isRedirect', 'redirected');
      } else {
        localStorage.removeItem('isRedirect');
      }
    } else {
      localStorage.setItem('isRedirect', 'redirected');
    }
    // eslint-disable-next-line
  }, [user]);

  const captcha = document.querySelector('.grecaptcha-badge');
  if (captcha) {
    captcha.style.zIndex = '2';
  }

  const handleUnderConstructionModalClose = useCallback(() => {
    dispatch(toggleUnderConstructionModalAction(false));
  }, [dispatch]);

  const showHeader = useMemo(() => {
    const routes = [routesByName.contact];
    return !routes.includes(pathname);
  }, [pathname]);

  return inited ? (
    <Suspense
      fallback={
        <div className="flex-center full-loader">
          <Preloader />
        </div>
      }
    >
      <UncompletedUserRedirect />
      <ConversationController />
      {showHeader && <Header />}
      <div className="flex-fill d-flex flex-column">
        <Switch>
          <Route exact path={routesByName.home}>
            <HomeContainer />
          </Route>
          <Route exact path={routesByName.auth.emailVerification}>
            <EmailVerificationContainer />
          </Route>
          <PrivateRoute path={routesByName.account.index}>
            <AccountContainer />
          </PrivateRoute>
          <PrivateRoute path={routesByName.dashboard.index}>
            <DashboardContainer />
          </PrivateRoute>
          <Route path={routesByName.getTheLook.index}>
            <GetTheLookContainer />
          </Route>
          <Route path={routesByName.curateTheLook.index}>
            <CurateTheLookContainer />
          </Route>
          <Route path={routesByName.requestTheLook.index}>
            <RequestTheLookContainer />
          </Route>
          <Route exact path={routesByName.lookBoard.details()}>
            <LookBoardDetails />
          </Route>
          <Route exact path={routesByName.product.details()}>
            <ProductDetails />
          </Route>
          <Route exact path={routesByName.inspirationImage.details()}>
            <InspirationImageDetails />
          </Route>
          <Route exact path={routesByName.contact}>
            <ContactContainer />
          </Route>
          <Route path={routesByName.ambassador.index}>
            <AmbassadorsContainer />
          </Route>
          <Route exact path={routesByName.showcase.preview}>
            <ShowcasePreview />
          </Route>
          <Route exact path={routesByName.showcase.details()}>
            <ShowcaseDetails userId={user?.id} />
          </Route>
          <Route path={routesByName.report.index}>
            <ReportContainer />
          </Route>
          <Route exact path={routesByName.about}>
            <AboutContainer />
          </Route>
          <Route path={routesByName.faqs}>
            <AmbassadorFaqs />
          </Route>
          <AmbassadorRoute exact path={routesByName.nominateAmbassador}>
            <NominateAmbassadorContainer />
          </AmbassadorRoute>
          <Route exact path={routesByName.tradeOpportunities}>
            <TradeOpportunitiesContainer />
          </Route>
          <Route exact path={routesByName.unavailableTool}>
            <UnavailableToolMobilePage />
          </Route>
          <Route exact path={routesByName.blog} component={UnderConstruction} />
          <Route exact path={routesByName.getThePin} component={GetThePin} />
          <Route exact path={routesByName.terms}>
            <Terms />
          </Route>
          <Route exact path={routesByName.privacy}>
            <Privacy />
          </Route>
          <Route exact path={routesByName.community}>
            <Community />
          </Route>
          <Route exact path={routesByName.styleQuiz}>
            <StyleQuizPage />
          </Route>
          <Route exact path={routesByName.howItworks} component={UnderConstruction} />
          <Route exact path={routesByName.getStartedNow} component={UnderConstruction} />
          <Route exact path="*" component={Page404} />
        </Switch>
      </div>
      {!matchesMediaQuery && showHeader && <PinMediaArea />}
      {showHeader && <Footer />}
      <ReduxToastr position="bottom-left" preventDuplicates />
      <UnderConstructionModal
        open={underConstructionModalOpen}
        onClose={handleUnderConstructionModalClose}
      />
      <ClaimImageModal />
      <TermsModal />
      <HowItWorksModal />
      <VerificationRemindModal />
      <PreferencesRemindModal />
      <NewsLetterModal />
    </Suspense>
  ) : (
    <div className="flex-center full-loader">
      <Preloader />
    </div>
  );
};

export default App;
