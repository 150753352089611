import {
  ambassadorFilterTabKeys,
  getTheLookAmbassadorsTabKeys,
} from 'modules/getTheLook/pages/ambassadorPage/config';

import {
  GTL_AMBASSADOR_LIKE_LOOKBOARD,
  GTL_AMBASSADOR_VOTE_LOOKBOARD,
  GTL_RESET_AMBASSADOR_FILTER_VALUES,
  GTL_SET_AMBASSADOR_FOLLOWED,
  GTL_SET_AMBASSADOR_USERS,
  GTL_SET_AMBASSADORS_ACTIVE_TAB,
  GTL_SET_SELECTED_AMBASSADOR,
  GTL_UPDATE_AMBASSADOR_FOLLOWED,
  GTL_UPDATE_AMBASSADOR_USERS,
  GTL_UPDATE_AMBASSADORS_FILTER_VALUES,
  GTL_UPDATE_AMBASSADORS_FOLLOWED_LIBRARY,
  GTL_UPDATE_AMBASSADORS_FOLLOWED_LOOKBOARDS_DATA,
  GTL_UPDATE_AMBASSADORS_LIBRARY,
  GTL_UPDATE_AMBASSADORS_LOOKBOARDS_DATA,
  GTL_UPDATE_AMBASSADORS_SEARCH_PARAMS,
  GTL_UPDATE_HAS_MORE_STATUS_AMBASSADOR,
  GTL_UPDATE_LOADING_STATUS_AMBASSADOR,
} from 'modules/getTheLook/pages/ambassadorPage/store/constants';

const initialAmbassadorsFilterValues = {
  [ambassadorFilterTabKeys.profession]: [],
  [ambassadorFilterTabKeys.styles]: [],
  [ambassadorFilterTabKeys.subStyles]: {},
  [ambassadorFilterTabKeys.colors]: [],
  [ambassadorFilterTabKeys.roomTypes]: [],
};

const initialAmbassadorsSearchParams = {
  search: '',
  offset: 0,
};

const initialState = {
  hasMore: true,
  loading: false,
  usersList: [],
  followedUsersList: [],
  usersLibrary: {},
  followedUsersLibrary: {},
  lookBoardsData: {},
  followedLookBoardsData: {},
  selectedAmbassador: null,
  activeTab: getTheLookAmbassadorsTabKeys.main,
  ambassadorsSearchParams: initialAmbassadorsSearchParams,
  ambassadorsFilterValues: initialAmbassadorsFilterValues,
  limit: 10,
};

const ambassadorsPageReducer = (
  state = { ...initialState },
  { type, payload }
) => {
  switch (type) {
    case GTL_UPDATE_LOADING_STATUS_AMBASSADOR: {
      return {
        ...state,
        loading: payload,
      };
    }
    case GTL_UPDATE_HAS_MORE_STATUS_AMBASSADOR: {
      return {
        ...state,
        hasMore: payload,
      };
    }
    case GTL_SET_AMBASSADOR_USERS: {
      return {
        ...state,
        usersList: payload,
      };
    }
    case GTL_SET_AMBASSADOR_FOLLOWED: {
      return {
        ...state,
        followedUsersList: payload,
      };
    }
    case GTL_UPDATE_AMBASSADOR_USERS: {
      return {
        ...state,
        usersList: [...state.usersList, ...payload],
      };
    }
    case GTL_UPDATE_AMBASSADORS_LIBRARY: {
      return {
        ...state,
        usersLibrary: { ...state.usersLibrary, ...payload },
      };
    }
    case GTL_UPDATE_AMBASSADOR_FOLLOWED: {
      return {
        ...state,
        followedUsersList: [...state.followedUsersList, ...payload],
      };
    }
    case GTL_UPDATE_AMBASSADORS_FOLLOWED_LIBRARY: {
      return {
        ...state,
        followedUsersLibrary: { ...state.followedUsersLibrary, ...payload },
      };
    }
    case GTL_UPDATE_AMBASSADORS_LOOKBOARDS_DATA: {
      return {
        ...state,
        lookBoardsData: { ...state.lookBoardsData, ...payload },
      };
    }
    case GTL_UPDATE_AMBASSADORS_FOLLOWED_LOOKBOARDS_DATA: {
      return {
        ...state,
        followedLookBoardsData: { ...state.followedLookBoardsData, ...payload },
      };
    }
    case GTL_SET_AMBASSADORS_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: payload,
      };
    }
    case GTL_UPDATE_AMBASSADORS_SEARCH_PARAMS: {
      return {
        ...state,
        ambassadorsSearchParams: {
          ...state.ambassadorsSearchParams,
          ...payload,
        },
      };
    }
    case GTL_UPDATE_AMBASSADORS_FILTER_VALUES: {
      return {
        ...state,
        ambassadorsFilterValues: {
          ...state.ambassadorsFilterValues,
          ...payload,
        },
      };
    }
    case GTL_RESET_AMBASSADOR_FILTER_VALUES: {
      return {
        ...state,
        ambassadorsFilterValues: { ...initialAmbassadorsFilterValues },
      };
    }
    case GTL_SET_SELECTED_AMBASSADOR: {
      return {
        ...state,
        selectedAmbassador: payload,
      };
    }
    case GTL_AMBASSADOR_LIKE_LOOKBOARD: {
      const { lookBoardId, isLiked } = payload;

      const currentLookBoard = { ...state.lookBoardsData[lookBoardId] };

      currentLookBoard.isLiked = isLiked;

      return {
        ...state,
        lookBoardsData: {
          ...state.lookBoardsData,
          [lookBoardId]: currentLookBoard,
        },
      };
    }
    case GTL_AMBASSADOR_VOTE_LOOKBOARD: {
      const { lookBoardId, isVoted } = payload;

      const currentLookBoard = { ...state.lookBoardsData[lookBoardId] };

      currentLookBoard.isVoted = isVoted;

      return {
        ...state,
        lookBoardsData: {
          ...state.lookBoardsData,
          [lookBoardId]: currentLookBoard,
        },
      };
    }
    default:
      return state;
  }
};

export default ambassadorsPageReducer;
