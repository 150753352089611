import sofa from 'assets/img/get-the-look-page/sofa.jpg';
import table from 'assets/img/get-the-look-page/table.jpg';
import stool from 'assets/img/get-the-look-page/stool.jpg';
import chair from 'assets/img/get-the-look-page/chair.jpg';

export const infoMenuConfig = {
  title: 'Get the Look',
  description: 'Get the Look Section Guide',
  items: [
    {
      id: 1,
      title: 'Inspiration Images Database',
      description:
        'Browse through popular Inspiration Images to use in curating a new Look Board. Use the Search feature ' +
        'to pull up images by keywords or use the Filter feature to narrow your search by room type,' +
        ' style, and color. You can also click on the Pin icon to add a new Inspiration Image from your computer or the Web.',
      icon: 'living-room',
    },
    {
      id: 2,
      title: 'Ambassador Look Boards',
      description:
        'Check out our Ambassador Showcases. You can search for Ambassadors specializing ' +
        'in your style or browse the directory to find one that catches your eye. When you click on the “Go to Showcase” button, you can review an Ambassador’s Style Story, favorite inspiration images, and Look Boards, ' +
        'Showcase pages feature unique inspiration images and Look Boards, most of which are not available in the Curated Looks section.',
      icon: 'followers',
    },
  ],
};

export const getTheLookTabKeys = {
  lookBoardView: 'lookBoardView',
  productStream: 'productStream',
  mixAndMatch: 'mixAndMatch',
  galleryView: 'galleryView',
  showcaseView: 'showcaseView',
};

export const getTheLookTabsConfig = [
  {
    value: getTheLookTabKeys.galleryView,
    title: 'Curated Looks',
    icon: 'dashboard',
  },
  {
    value: getTheLookTabKeys.mixAndMatch,
    title: 'Mix & Match Looks',
    icon: 'shuffle-icon',
  },
  {
    value: getTheLookTabKeys.lookBoardView,
    title: 'Look Boards View',
    icon: 'followers',
  },

  {
    value: getTheLookTabKeys.productStream,
    title: 'Product View',
    icon: 'living-room',
  },
];

export const mixMatchModalMessages = {
  imageNotSelected:
    'Please, select inspiration image or item first to Mix & Match',
  typeSingle:
    'This option is available only for Room images and coordinating items',
};

export const mobileViewStepsConfig = [
  { number: 1, description: 'Select Image Type' },
  { number: 2, description: 'Select Inspiration Image' },
  { number: 3, description: 'View the Look' },
];

export const lookBoardInfoConfig = {
  title: 'What is Look Board?',
  description:
    'Look Boards are curated collages of products selected to Get the Look of a room image or show Coordinating items.',
};

export const mixMatchSteps = [
  {
    id: 1,
    title: 'Select an Image You Love',
  },
  {
    id: 2,
    title: 'Slide through the Mix & Match Options to Get the Look You Love!',
  },
  {
    id: 3,
    title: 'Create Look Boards with Your Favorite Combination of Items.',
  },
];

export const mixMatchExamples = [
  {
    title: 'Sofa',
    activeDot: 1,
    img: sofa,
  },
  {
    title: 'Table',
    activeDot: 3,
    img: table,
  },
  {
    title: 'Stool',
    activeDot: 2,
    img: stool,
  },
  {
    title: 'Chair',
    activeDot: 1,
    img: chair,
  },
];

export const registerTimeoutConfig = {
  first: 1000 * 60,
  second: 1000 * 60 * 3,
};

export const stylePreferencesTimeoutConfig = {
  first: 1000 * 60 * 5, // 5 min
};

// export const unauthorizedLimit = 100;
export const productsSortKeys = {
  bestMatch: 'bestMatch',
  mostLikes: 'mostLikes',
  priceLowToHigh: 'priceLowToHigh',
  priceHighToLow: 'priceHighToLow',
};

export const productsSortOptions = [
  {
    label: 'Best Image Match',
    value: productsSortKeys.bestMatch,
  },
  {
    label: 'Most User Likes',
    value: productsSortKeys.mostLikes,
  },
  {
    label: 'Lowest Prices',
    value: productsSortKeys.priceLowToHigh,
  },
];

export const productViewsTypes = {
  curated: 'curated',
  products: 'products',
};

export const lookBoardSearchTypes = {
  image: 'image',
  furnishings: 'furnishings',
};
