import {
  PRODUCT_UPDATE_LIBRARY,
  SET_ACTIVE_PRODUCT_LB_MODAL,
  SET_LOOK_BOARDS_BY_PRODUCT,
} from 'modules/product/store/constants';
import productService from '../productService';
import mergeLookBoardsWithProducts from '../../../utils/mergeLookBoardsWithProducts';

export const updateProductsLibraryAction = (list) => (dispatch) => {
  dispatch({ type: PRODUCT_UPDATE_LIBRARY, payload: list });
};

export const updateLookBoardsByProductAction = (payload) => (dispatch) => {
  return dispatch({ type: SET_LOOK_BOARDS_BY_PRODUCT, payload });
};
export const updateActiveProductsLBModalAction = (payload) => (dispatch) => {
  return dispatch({ type: SET_ACTIVE_PRODUCT_LB_MODAL, payload });
};

export const updateLookBoardsByProductIdAction = (id, params) => async (dispatch) => {
  const info = await productService.getLookBoardsByProduct(id, params);
  const lookBoardList = mergeLookBoardsWithProducts(info.result.lookBoards, info.result.products);

  dispatch(
    updateLookBoardsByProductAction({ [id]: { ...info.result, lookBoards: lookBoardList } }),
  );
};

export const openLookBoardsByProductIdModalAction = (id, params) => async (dispatch) => {
  dispatch(updateActiveProductsLBModalAction({ id, loading: true }));

  dispatch(updateLookBoardsByProductIdAction(id, params));

  dispatch(updateActiveProductsLBModalAction({ id, loading: false }));
};
