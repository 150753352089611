export const GTL_SET_ACTIVE_TAB = 'GTL_SET_ACTIVE_TAB';
export const GTL_UPDATE_SEARCH_PARAMS = 'GTL_UPDATE_SEARCH_PARAMS';
export const GTL_UPDATE_LOADING_STATUS = 'GTL_UPDATE_LOADING_STATUS';
export const GTL_UPDATE_HAS_MORE_STATUS = 'GTL_UPDATE_HAS_MORE_STATUS';
export const GTL_SET_IMAGES = 'GTL_SET_IMAGES';
export const GTL_UPDATE_IMAGES = 'GTL_UPDATE_IMAGES';
export const GTL_UPDATE_LOOK_BOARDS_DATA = 'GTL_UPDATE_LOOK_BOARDS_DATA';
export const GTL_SET_SELECTED_IMAGE = 'GTL_SET_SELECTED_IMAGE';
export const GTL_UPDATE_FILTER_VALUES = 'GTL_UPDATE_FILTER_VALUES';
export const GTL_RESET_FILTER_VALUES = 'GTL_RESET_FILTER_VALUES';
export const GTL_LIKE_LOOKBOARD = 'GTL_LIKE_LOOKBOARD';
export const GTL_VOTE_LOOKBOARD = 'GTL_VOTE_LOOKBOARD';
export const GTL_UPDATE_MIX_MATCH_TYPE = 'GTL_UPDATE_MIX_MATCH_TYPE';
export const GTL_UPDATE_MIX_MATCH_SUBTYPE = 'GTL_UPDATE_MIX_MATCH_SUBTYPE';
export const GTL_UPDATE_MIX_MATCH_SORT = 'GTL_UPDATE_MIX_MATCH_SORT';
export const GTL_SET_LIKED_IMAGES = 'GTL_SET_LIKED_IMAGES';
export const GTL_SET_LIKED_LOOK_BOARDS = 'GTL_SET_LIKED_LOOK_BOARDS';

export const GTL_UPDATE_ALL_LOOK_BOARDS_DATA =
  'GTL_UPDATE_ALL_LOOK_BOARDS_DATA';
export const GTL_UNLIKE_IMAGE = 'GTL_UNLIKE_IMAGE';

export const GTL_UPDATE_PRODUCT_SORT = 'GTL_UPDATE_PRODUCT_SORT';
export const GTL_SET_PRODUCT_VIEW = 'GTL_SET_PRODUCT_VIEW';
export const GTL_UPDATE_PRODUCT_FILTER_VALUES =
  'GTL_UPDATE_PRODUCT_FILTER_VALUES';
export const GTL_UPDATE_PRODUCT_SEARCH_PARAMS =
  'GTL_UPDATE_PRODUCT_SEARCH_PARAMS';
export const GTL_RESET_PRODUCT_SEARCH_PARAMS =
  'GTL_RESET_PRODUCT_SEARCH_PARAMS';
export const GTL_RESET_PRODUCT_FILTER_VALUES =
  'GTL_RESET_PRODUCT_FILTER_VALUES';
export const GTL_CLEAR_PRODUCT_FILTER_VALUES =
  'GTL_CLEAR_PRODUCT_FILTER_VALUES';
export const GTL_SET_LOOK_BOARD_SEARCH_TYPE = 'GTL_SET_LOOK_BOARD_SEARCH_TYPE';
