import {
  PRODUCT_REMOVE_INSTANTLY_CREATED,
  PRODUCT_UPDATE_INSTANTLY_CREATED,
  PRODUCT_UPDATE_LIBRARY,
  SET_ACTIVE_PRODUCT_LB_MODAL,
  SET_LOOK_BOARDS_BY_PRODUCT,
} from 'modules/product/store/constants';

const initialState = {
  library: {},
  createdInstantlyProductIds: [],
  lookBoardsByProduct: {},
  activeProductLBModalInfo: {
    id: null,
  },
};

const productsReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case PRODUCT_UPDATE_LIBRARY: {
      return {
        ...state,
        library: {
          ...state.library,
          ...payload,
        },
      };
    }
    case PRODUCT_UPDATE_INSTANTLY_CREATED: {
      return {
        ...state,
        createdInstantlyProductIds: [payload, ...state.createdInstantlyProductIds],
      };
    }
    case PRODUCT_REMOVE_INSTANTLY_CREATED: {
      return {
        ...state,
        createdInstantlyProductIds: [],
      };
    }
    case SET_LOOK_BOARDS_BY_PRODUCT: {
      return {
        ...state,
        lookBoardsByProduct: {
          ...state.lookBoardsByProduct,
          ...payload,
        },
      };
    }
    case SET_ACTIVE_PRODUCT_LB_MODAL: {
      return {
        ...state,
        activeProductLBModalInfo: {
          ...payload,
        },
      };
    }
    default:
      return state;
  }
};

export default productsReducer;
